<template>
  <div class="px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <h2 class="main-title">Current Month Data Usage</h2>
      </div>
    </div>
    <div class="row line-count-overview">
      <div class="col-12 col-sm-12 col-lg-6">
        <div class="card justify-content-start">
          <div class="">
            <div class="">
              <h2>Current Cycle</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-4 mt-3 text-center br-lg-1">
              <p class="title">Total Usage</p>
              <p class="cost">
                {{ parseFloat(dataInfo.current_usage_value).toFixed(2) }} GB
              </p>
              <p class="mb-0 alert-message">
                <strong>{{ dataInfo.current_usage_percentage }}%</strong>
                usage of pool
              </p>
            </div>
            <div class="col-12 col-sm-12 col-md-4 mt-3 text-center br-lg-1">
              <p class="title">Data Pool Size</p>
              <p class="cost">{{ dataInfo.current_pool_value }} GB</p>
            </div>
            <div class="col-12 col-sm-12 col-md-4 mt-3 text-center">
              <p class="title">Days left in Billing Cycle</p>
              <p class="cost">{{ dataInfo.days_left }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-6 mt-4 mt-lg-0">
        <div class="card justify-content-start">
          <div class="">
            <div class="">
              <h2>Projected Usage</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-4 mt-3 text-center br-lg-1">
              <p class="title">Projected Usage</p>
              <p class="cost">{{ dataInfo.projected_usage }} GB</p>
            </div>
            <div class="col-12 col-sm-12 col-md-4 mt-3 text-center br-lg-1">
              <p class="title">Overage</p>
              <p class="cost">{{ dataInfo.projected_overage }}</p>
            </div>
            <div class="col-12 col-sm-12 col-md-4 mt-3 text-center">
              <p class="title">Total Overage Amount</p>
              <p class="cost">
                ${{
                  parseFloat(dataInfo.projected_overage_cost)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-sm-12">
        <div class="card">
          <div class="row">
            <div class="col-12 col-sm-12">
              <h2>This Month's Data Usage</h2>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 col-sm-12">
              <highcharts
                class="optimizerChart"
                :options="stockOptions"
              ></highcharts>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-sm-12">
        <div class="card">
          <ul class="list-table px-md-3">
            <li class="list-table-row list-table-header">
              <ul>
                <li class="list-table-column col-lg-4">
                  Billing Period
                </li>
                <li class="list-table-column col-lg-4 text-center">
                  Total Data Usage
                </li>
                <li class="list-table-column col-lg-4 text-center">
                  Data Pool Size
                </li>
              </ul>
            </li>
            <div class="line-blue-3 mt-3 mb-4"></div>
            <li
              class="list-table-row"
              v-for="(item, key) in montly_usage"
              :key="key"
            >
              <ul>
                <li class="list-table-column col-lg-4">
                  {{ format(item.updated_on) }}
                </li>
                <li class="list-table-column col-lg-4 text-center">
                  {{ getDataGb(item.data_pool / 1024) }}
                </li>
                <li class="list-table-column col-lg-4 text-center">
                  {{ getDataGb(item.current_usage / 1024) }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-table-header {
  ul {
    font-size: 1rem;
  }
}
.line-count-overview {
  .title {
    letter-spacing: 2px;
  }
  .cost {
    font-family: "Circular", sans-serif;
    font-weight: bold;
    font-size: 32px;
  }
  .alert-message {
    color: #ff502d;
    margin-bottom: 0;
    i {
      margin-right: 0.5rem;
    }
  }
  .br-0 {
    border-right: 0;
  }
}

@media screen and (max-width: 1440px) {
  .line-count-overview {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 36px;
    }
  }
}
@media screen and (max-width: 1439px) and (min-width: 991px) {
  .line-count-overview {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 24px;
    }
  }
}
@media screen and (max-width: 767px) {
  .line-count-overview {
    .title {
      letter-spacing: 0;
    }
    .cost {
      font-size: 32px;
    }
  }
}
</style>
<script>
export default {
  props: ["id_report", "login_data"],
  data() {
    return {
      loading: true,
      dataInfo: [],
      stockOptions: {},
      modalGraph: {},
      billing_data: [
        {
          period: "January 14, 2020",
          data_size: "548 GB",
          data_usage: "52.45 GB"
        },
        {
          period: "November 14, 2019",
          data_size: "548 GB",
          data_usage: "548 GB"
        },
        {
          period: "November 14, 2018",
          data_size: "548 GB",
          data_usage: "548 GB"
        },
        {
          period: "January 14, 2020",
          data_size: "548 GB",
          data_usage: "548 GB"
        },
        {
          period: "November 14, 2019",
          data_size: "548 GB",
          data_usage: "548 GB"
        },
        {
          period: "November 14, 2018",
          data_size: "548 GB",
          data_usage: "548 GB"
        }
      ],
      montly_usage: []
    };
  },
  async created() {
    const self = this;

    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      }
    } else {
      this.$router.push("/");
    }

    //GET THE COST CENTRE DATA AND SAVE IT IN [cost_centre_data]
    await this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/data_usage`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(async function(response) {
        self.dataInfo = response.data;
      })
      .catch(error => this.makeToast(error, "danger"));

    this.stockOptions = {
      chart: { type: "areaspline" },
      credits: {
        enabled: false
      },
      title: { text: "" },
      legend: { enabled: true },
      xAxis: {
        gridLineColor: "#999999",
        gridLineWidth: 1,
        categories: this.dataInfo.dates,
        tickmarkPlacement: "on",
        title: {
          enabled: false
        },
        labels: {
          rotation: -60,
          style: {
            fontFamily: "'Circular Book', sans-serif",
            fontSize: "1.35em",
            color: "#343f63"
          }
        }
      },
      yAxis: {
        gridLineColor: "transparent",
        labels: {
          enabled: true,
          format: "{value} GB",
          formatter() {
            return (
              "<span>" +
              this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " GB</span>"
            );
          },
          style: {
            fontFamily: "'Circular Book', sans-serif",
            fontSize: "1.25em",
            color: "#343f63"
          }
        },
        title: {
          enabled: false
        }
      },
      tooltip: {
        formatter() {
          return `<strong>${this.point.y} GB</strong>`;
        }
      },
      series: [
        {
          name: "Data Pool",
          tooltip: {
            split: true
          },
          data: this.dataInfo.pool,
          lineWidth: 4,
          marker: {
            symbol: "circle",
            radius: 6
          },
          color: "#343f63",
          fillColor: "rgba(64, 143, 222, 0)"
        },
        {
          name: "Data Usage",
          tooltip: {
            split: true
          },
          data: this.dataInfo.current_usage,
          lineWidth: 3,
          marker: {
            symbol: "circle",
            radius: 5
          },
          color: "#ff502d",
          fillColor: "rgba(255, 93, 93, 0.1)"
        },
        {
          name: "Projected Usage",
          tooltip: {
            split: true
          },
          data: this.dataInfo.forecast,
          lineWidth: 3,
          marker: {
            symbol: "circle",
            radius: 5
          },
          color: "#f2a78c",
          fillColor: "rgb(255, 247, 247, 0.2)"
        }
      ]
    };

    this.montly_usage = self.dataInfo.montly_usage;
  },
  methods: {
    format(input) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June ",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      let date = input.split("-");
      let month = parseInt(date[1]) - 1;
      let day = date[2];
      let year = date[0];
      return `${months[month]} ${day}, ${year}`;
    },
    getDataGb(value) {
      let er = /^-?[0-9]+$/;
      let res = er.test(value);
      let data = "";
      if (res) {
        data = value;
      } else {
        data = value.toFixed(2);
      }
      return data + " GB";
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    }
  }
};
</script>

<style lang="scss">
.line-count-overview {
  p {
    margin-bottom: 0;
  }
}
</style>
